<template>
   <div id="app">
    	<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>

<script>
export default {
        name: 'App',
        provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。                                             
            return {
                reload: this.reload                                              
            }
        },
        data() {
            return{
                isRouterAlive: true                    //控制视图是否显示的变量
            }
        },
        created:function(){
        	var _hmt = _hmt || [];
			(function() {
			  var hm = document.createElement("script");
			  hm.src = "https://hm.baidu.com/hm.js?b7f6eec4d8a4032ce665bc0026770383";
			  var s = document.getElementsByTagName("script")[0]; 
			  s.parentNode.insertBefore(hm, s);
			})();

        },
        methods: {
            reload () {
                this.isRouterAlive = false;            //先关闭，
                this.$nextTick(function () {
                    this.isRouterAlive = true;         //再打开
                }) 
            }
        }
    }
</script>

<style lang="less">
#app{
    height: 100%;
}
.main{
    max-width: 1270px;
    margin: auto;
}
p{
    // text-align: justify;
    font-family:"Microsoft YaHei","微软雅黑","Microsoft JhengHei","华文细黑","STHeiti","MingLiu";
}
// 默认样式
input[type="search"]::-webkit-search-cancel-button{
    display: none;
}
input[type="search"]{
    -webkit-appearance: none;
}
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
dl,
dt,
dd {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover {
    color: #333;
}

img {
    border: 0 none;
    vertical-align: middle;
}

.centre {
    height: 100%;
}

input,
textarea {
    outline: none;
    border: 0 none;
}

body {
    font: 14px/1.5 arial, 'microsoft yahei';
    color: #333;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

i,
em {
    font-style: normal;
}


</style>
