import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [

  {
    path: '/', 
    name: 'nav',
    component: resolve => (require(["@/components/nav"], resolve)),
    children:[
      {path: '/', name: 'index',meta: {title:'依蓝云 - 专注智慧交通场景应用解决方案'},component: resolve => (require(["@/views/index"], resolve)),}, //首页
      {path: '/introduce', name: 'introduce',meta: {title:'依蓝云公司 - 简介'},component: resolve => (require(["@/views/introduce"], resolve)),},  //简介
      {path: '/work', name: 'work',meta: {title:'依蓝云公司 - 业务'},component: resolve => (require(["@/views/work"], resolve)),},  //业务
      {path: '/staff', name: 'staff',meta: {title:'依蓝云公司 - 员工'},component: resolve => (require(["@/views/staff"], resolve)),},  //员工
      {path: '/service', name: 'service',meta: {title:'依蓝云公司 - 服务'},component: resolve => (require(["@/views/service"], resolve)),},  //服务
      {path: '/map', name: 'map',meta: {title:'依蓝云公司 - 网站地图'},component: resolve => (require(["@/views/map"], resolve)),},  //服务
      {path: '/enterprise', name: 'enterprise',meta: {title:'依蓝云公司 - 企业责任'},component: resolve => (require(["@/views/enterprise/index"], resolve)),},  //企业责任
      {path: '/details', name: 'details',meta: {title:'依蓝云公司 - 企业责任详情'},component: resolve => (require(["@/views/enterprise/details"], resolve)),},  //企业详情
    ]
  },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    // 默认会有个记录滚动问题
    scrollBehavior(to, from, savedPssition){
      if(savedPssition){
        return savedPssition;
      }else{
          return {x:0,y:0}
      }
    }
})
router.afterEach(() => {
  window.scrollTo(0,0);
})
router.beforeEach((to, from, next) => {
    if(to.meta.title){
        window.document.title = to.meta.title
    } else {
        next()
    }
    next()
})

export default router
